import * as React from "react"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from '../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const BlogPage = ({data}) => {
  const entry = data.prismicBlogPage
  const blog = data.allPrismicBlog
  if (!entry) return null
  return (
    <div className="bg-diamond-10">
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <div className="w-full bg-black rounded-br-[133px]">
        <div className="flex-none md:flex justify-between">
          <div className="w-full md:w-6/12">
            <GatsbyImage className="w-full h-full object-cover block py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-16 py-16 md:py-36">
            <div className="leading-snug text-2xl text-white font-poppins font-semibold">
              <h2 className="mb-8">
                <span className="bg-white text-3xl text-orange px-3 py-1 font-bold leading-tight inline-block">{entry.data.heading}</span>
              </h2>
              <p className="ml-2">{entry.data.text}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-diamond-10 pt-16 pb-8">
        <div className="w-9/12 md:w-7/12 mx-auto max-w-screen-xl text-center">
          <div className="font-poppins leading-snug text-2xl md:text-3xl">
            <h2 className="mb-3"><span className="bg-white block md:inline-block px-6 py-1 font-bold text-diamond">{entry.data.sub_heading1}</span></h2>
            <h2><span className="ml-0 md:ml-6 text-orange font-semibold">{entry.data.sub_heading2}</span></h2>
          </div>
        </div>
      </div>
      <div className="w-full bg-diamond-10 pb-16 rounded-br-[133px]">
        <div className="w-10/12 md:w-7/12 mx-auto max-w-screen-xl">
          <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
            <PrismicRichText linkResolver={linkResolver} field={entry.data.sub_text.richText} />
          </div>
        </div>
      </div>
      <div className="w-full bg-white rounded-tr-[133px]">
        <div className="py-16">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
              {blog.edges.map((entry,i) => {
                //console.log('entry: ', entry)
                return (
                  <div key={i}>
                    <Link to={`/blog/${entry.node.uid}/`}>
                    <figure>
                      <GatsbyImage className="aspect-w-4 aspect-h-3 rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px] w-full h-full block object-cover" image={entry.node.data.image.gatsbyImageData} alt={entry.node.data.image.alt ?? ''} />
                    </figure>
                    <figcaption className="font-poppins max-w-none prose prose-h2:text-xl prose-h2:text-orange prose-h2:mb-3 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
                      <h2>{entry.node.data.heading}</h2>
                      <p>{entry.node.data.snippet}</p>
                    </figcaption>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
query {
  prismicBlogPage {
    data {
      meta_description
      page_title
      text
      heading
      image {
        alt
        gatsbyImageData
      }
      sub_heading1
      sub_heading2
      sub_text {
        richText
      }
    }
  }
  allPrismicBlog(sort: {fields: last_publication_date, order: DESC}) {
    edges {
      node {
        uid
        last_publication_date(formatString: "MMM DD, YYYY")
        data {
          heading
          image {
            gatsbyImageData
            alt
          }
          snippet
        }
      }
    }
  }
}
`

export default BlogPage
